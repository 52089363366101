





































































































































































































































.container {
    background-color: #f8f8f8;
    height: 100%;
    // height: calc(100vh + 110px);
    .content {
        background-color: #f8f8f8;
        padding-bottom: 1rem;
    }
    .head_right {
        color: #ffffff;
        font-size: 14px;
    }
}
.nav {
    background-color: #ffffff;
    font-family: PingFang SC;
    .nav_item1 {
        font-size: 14px;
        padding: 12px 33px;
        color: #8b9fad;
        border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 40px;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item2 {
        font-size: 14px;
        padding: 12px 33px;
        color: #8b9fad;
        border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 20px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item3 {
        font-size: 14px;
        padding: 12px 33px;
        color: #8b9fad;
        border-bottom: 1px solid #f1f1f1;
        .item {
            margin-left: 33px;
            text-align: center;
        }
        .item:first-child {
            margin-left: 0;
        }
    }
    .nav_item4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding: 12px 34px;
        color: #333333;
        .item4_1 {
            border-radius: 4px;
            background-color: #f5f5f5;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .item4_2 {
            border-radius: 4px;
            background-color: #f5f5f5;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .item4_3 {
            border-radius: 4px;
            background-color: #f5f5f5;
            padding: 6px 5px 6px 5px;
            text-align: center;
        }
        .active1 {
            color: #5899f6;
            background-color: #e8f1fe;
            font-weight: 500;
        }
    }
    .active {
        color: #5899f6;
        font-size: 15px;
        font-weight: 500;
    }
}
.content {
    .teacher_list {
        background-color: #ffffff;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0 3px 9px rgba(0, 0, 0, 0.1);
        font-family: PingFang SC;
        display: flex;
        justify-content: space-between;
        .msg_left {
            margin: 10px 0;
            // margin-left: 0.1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .img {
                width: 1.2rem;
                height: 1.2rem;
                img {
                    border-radius: 0.5rem;
                    width: 100%;
                    height: 100%;
                }
            }
            .name {
                font-size: 28rpx;
                font-weight: 500;
                color: #333333;
                text-align: center;
            }
            .VIP {
                display: flex;
                align-items: center;
                .img_vip {
                    // width: 0.24rem;
                    // height: 0.24rem;
                    img {
                        width: 0.252399rem;
                        height: 0.2218rem;
                    }
                }
                .vip_number {
                    margin-left: 0.05rem;
                    font-size: 0.24rem;
                    color: #333333;
                }
            }
            .bili {
                margin-top: 0.1rem;
                background-color: #e8f1fe;
                padding: 0.08rem 0.28rem 0.06rem 0.28rem;
                border-radius: 0.08rem;
                font-size: 0.2rem;
                color: #5899f6;
                text-align: center;
            }
        }
        .msg_right {
            // margin-left: 0.3rem;
            margin-top: 0.6rem;
            .top {
                display: flex;
                align-items: center;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.58rem;
                    text-align: left;
                }
                .txt {
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
            .bottom {
                margin-top: 0.6rem;
                display: flex;
                align-items: center;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.2rem;
                    text-align: left;
                }
                .txt {
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
        }
        .msg_right1 {
            margin-top: 1.04rem;
            margin-right: 0.72rem;
            .top {
                display: flex;
                align-items: center;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.58rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
            .bottom {
                margin-top: 0.16rem;
                display: flex;
                align-items: center;
                .top_left {
                    text-align: left;
                }
                .top_right {
                    margin-left: 0.8rem;
                    text-align: left;
                }
                .txt {
                    text-align: left;
                    margin-left: -0.09rem;
                    color: #666666;
                    font-size: 0.24rem;
                    transform: scale(0.83);
                }
                .txt1 {
                    margin-top: 0.15rem;
                    text-align: left;
                    color: #666666;
                    font-size: 0.24rem;
                }
                .left_num {
                    color: #5998fc;
                    font-size: 0.3rem;
                }
                .right_num {
                    color: #d98181;
                    font-size: 0.3rem;
                }
            }
        }
    }
}


@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;